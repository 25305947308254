import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@mozartfinance/uikit'
import useI18n from 'hooks/useI18n'

const StyledNav = styled.div`
  margin-bottom: 40px;
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n()
  return (
    <StyledNav>
      <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
        <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
          {TranslateString(1142, 'Swap')}
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
          {TranslateString(262, 'Liquidity')}
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" href="https://bitfinity.network/faucet" as="a" target="_blank" rel="noreferrer noopener">
          {TranslateString(262, 'Faucet')}
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" href="https://defi.testnet.chapswap.com" as="a" rel="noreferrer noopener">
          {TranslateString(262, 'Defi')}
        </ButtonMenuItem>
        {/* <ButtonMenuItem id="pool-nav-link" href="https://na2wq-3qaaa-aaaal-ajokq-cai.icp0.io/" as="a" rel="noreferrer noopener">
          {TranslateString(262, 'Launchpad(BETA)')}
        </ButtonMenuItem> */}
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
