import { TokenList } from '@uniswap/token-lists'
import schema from '@uniswap/token-lists/src/tokenlist.schema.json'
import Ajv from 'ajv'
import contenthashToUri from './contenthashToUri'
import { parseENSAddress } from './parseENSAddress'
import uriToHttp from './uriToHttp'

// bakeryswap defaultTokenJson
import { DEFAULT_TOKEN_LIST_URL } from '../constants/lists'

const defaultTokenListUrl = 'https://raw.githubusercontent.com/chapswap/assets/main/token/list/testnet/main.json'

const tokenListValidator = new Ajv({ allErrors: true }).compile(schema)

/**
 * Contains the logic for resolving a list URL to a validated token list
 * @param listUrl list url
 * @param resolveENSContentHash resolves an ens name to a contenthash
 */
export default async function getTokenList(
  listUrl: string,
  resolveENSContentHash: (ensName: string) => Promise<string>
): Promise<TokenList> {
  let json: TokenList

  // Check if the listUrl is the default token list URL
  if (listUrl === DEFAULT_TOKEN_LIST_URL) {
    // Fetch the default token list JSON
    const response = await fetch(defaultTokenListUrl)
    if (!response.ok) {
      throw new Error(`Failed to fetch default token list from ${defaultTokenListUrl}`)
    }
    json = await response.json()
  } else {
    const parsedENS = parseENSAddress(listUrl)

    let urls: string[]
    if (parsedENS) {
      let contentHashUri
      try {
        contentHashUri = await resolveENSContentHash(parsedENS.ensName)
      } catch (error) {
        console.error(`Failed to resolve ENS name: ${parsedENS.ensName}`, error)
        throw new Error(`Failed to resolve ENS name: ${parsedENS.ensName}`)
      }
      let translatedUri
      try {
        translatedUri = contenthashToUri(contentHashUri)
      } catch (error) {
        console.error('Failed to translate contenthash to URI', contentHashUri)
        throw new Error(`Failed to translate contenthash to URI: ${contentHashUri}`)
      }
      urls = uriToHttp(`${translatedUri}${parsedENS.ensPath ?? ''}`)
    } else {
      urls = uriToHttp(listUrl)
    }

    // Fetch the token list from the resolved URLs
    const responses = await Promise.all(urls.map(url => fetch(url)))
    const validResponse = responses.find(response => response.ok)

    if (!validResponse) {
      throw new Error(`Failed to download token list from any of the URLs: ${urls.join(', ')}`)
    }

    json = await validResponse.json()

    if (!tokenListValidator(json)) {
      const validationErrors: string =
        tokenListValidator.errors?.reduce<string>((memo, error) => {
          const add = `${error.dataPath} ${error.message ?? ''}`
          return memo.length > 0 ? `${memo}; ${add}` : `${add}`
        }, '') ?? 'unknown error'
      throw new Error(`Token list failed validation: ${validationErrors}`)
    }
  }

  return json
}
